#Head {
  height: 100vh;
  background-image: url("../../img/bg_head.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .FullName {
    font-size: 80pt;
    font-family: Slabo, serif; }

  .Profession {
    font-size: 40pt;
    font-family: Bitter, serif;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, #2F5E93 15%, #082043 40%, #082043 60%, #2F5E93 85%, rgba(0,0,0,0) 100%); }

  .AboutButton {
    border: 1px solid #f9f9f9;
    background: rgb(112,140,144);
    background: radial-gradient(circle, #050B1C 0%, #082043 30%, #2F5E93 100%);
    background-color: #2F5E93;
    box-shadow: 0.15rem 0.2rem 0.1rem black;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    &:hover {
      color: #050B1C;
      background: #f9f9f9;
      -webkit-transition-duration: 0.5s;
      -moz-transition-duration: 0.5s;
      -o-transition-duration: 0.5s;
      transition-duration: 0.5s; }
    .Text {
      font-family: Bitter, serif;
      font-size: 20pt; } } }

@media (max-width: 750px) {
  #Head {
    .FullName {
      font-size: 50pt; }
    .Profession {
      font-size: 20pt; }
    .AboutButton {
      .Text {
        font-size: 15pt; } } } }
