#About {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9f9;
  background-image: url("../../img/bg_key-skills.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

#Portrait .Image {
  height: 12rem;
  width: 12rem;
  box-shadow: 0 0 .3rem .2rem #050B1C;
  background-image: url("../../img/portrait.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

#CvButton, #ProjectsButton {
  border: 1px solid #f9f9f9;
  background: rgb(112,140,144);
  background: radial-gradient(circle, #050B1C 0%, #082043 30%, #2F5E93 100%);
  background-color: #2F5E93;
  box-shadow: 0.15rem 0.2rem 0.1rem black;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  &:hover {
    color: #050B1C;
    background: #f9f9f9;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s; }
  .Text {
    font-family: Bitter, serif; } }

#Bio {
  color: #f9f9f9;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(0,0,0,0) 0%, #2F5E93 20%, #082043 50%, #2F5E93 70%, rgba(0,0,0,0) 100%);
  font-weight: 700;
  .Text {
    font-family: 'Roboto Condensed', serif;
    font-size: 16pt; } }

#Skills {
  .Header {
    color: #050B1C;
    background: rgb(255,255,255);
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(247,247,247,1) 50%, rgba(255,255,255,0) 100%);
    width: 35%;
    font-family: Bitter, serif; }
  .SkillsRow {
    h5 {
      font-family: Bitter, serif;
      font-size: 13pt;
      font-weight: bold; }
    h6 {
      font-family: Slabo, serif;
      font-size: 14pt; } } }
