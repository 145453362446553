#Contact {
  background: rgb(52,58,64);
  background: linear-gradient(90deg, #050B1C 0%, #2F5E93 50%, #050B1C 100%);

  .Header {
    font-family: Bitter, serif;
    color: #082043;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(247,247,247,1) 30%, rgba(247,247,247,1) 70%, rgba(255,255,255,0) 100%); } }

.Social {
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 17pt;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  z-index: 999;
  border: 1px solid #f9f9f9;
  background: radial-gradient(circle, #050B1C 0%, #082043 30%, #2F5E93 100%), #2F5E93;
  box-shadow: 0.15rem 0.2rem 0.1rem black;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  font-family: Bitter, serif;
  &:hover {
    color: #050B1C;
    background: #f9f9f9;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s; } }

