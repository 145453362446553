body {
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;

  &.on {
    padding-left: 10rem;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s; } }

.Toggler {
  height: 5rem;
  width: 5rem;
  position: fixed;
  top: 3rem;
  left: 4rem;
  border: 1px solid #f9f9f9;
  z-index: 999;
  background: rgb(112,140,144);
  background: radial-gradient(circle, #050B1C 0%, #082043 50%, #2F5E93 100%);
  background-color: #2F5E93;
  box-shadow: 0.15rem 0.2rem 0.1rem black;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  &:hover {
    color: #2F5E93;
    background: #f9f9f9;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s; } }

.Nav {
  top: 0;
  left: -13rem;
  padding-bottom: 5rem;
  position: fixed;
  height: 100vh;
  width: 13rem;
  z-index: 998;
  background: rgb(35,35,37);
  background: linear-gradient(0deg, #050B1C 0%, #2F5E93 50%, #050B1C 100%);
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;

  &.on {
    left: 0;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s; }

  a {
    text-decoration: none !important; }

  .Item {
    font-family: Slabo, serif;
    font-size: 17pt;
    font-weight: 600;
    width: 100%;
    color: #f9f9f9;
    margin: 1rem 0 1rem 0;
    text-decoration: none !important;
    text-underline: none !important;

    &:hover, &.Active {
      color: #1f4c52;
      background: #f9f9f9;
      -webkit-transition-duration: 0.5s;
      -moz-transition-duration: 0.5s;
      -o-transition-duration: 0.5s;
      transition-duration: 0.5s; } } }
