#Projects {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url("img/bg_projects.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .Header {
    color: #082043;
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(247,247,247,1) 30%, rgba(247,247,247,1) 70%, rgba(255,255,255,0) 100%);

    .Text {
      font-family: Slabo, serif;
      font-size: 40pt;
      font-weight: bold; } }

  .Name {
    color: #082043;
    font-family: Slabo, serif;
    font-weight: bold;
    font-size: 20pt; }

  .Project {
    .Image, .Body {
      min-height: 18rem;
      outline: solid #082043;
      font-family: 'Roboto Condensed', serif;
      color: #050B1C; } } }

.CodeButton, .LiveButton {
  border: 1px solid #f9f9f9;
  background: radial-gradient(circle, #050B1C 0%, #082043 30%, #2F5E93 100%), #2F5E93;
  box-shadow: 0.15rem 0.2rem 0.1rem black;
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
  font-family: Bitter, serif;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  &:hover {
    color: #050B1C;
    background: #f9f9f9;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s; } }

#GoTeam .Image {
  background-image: url("img/goteam.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

#PizzaStore .Image {
  background-image: url("img/portfolio-2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

#GitHubFinder .Image {
  background-image: url("img/portfolio-3.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

#LandingPage .Image {
  background-image: url("img/portfolio-4.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
